import React from 'react';

// map 'Icon Pack 2' in sketch; 'Bold - Outline' in design doc.
// exclude Social Icons
export const account = (
  <path
    fillRule='evenodd'
    d='M19.62 19.29l.026.71h-2.421l-.028-.658c-.119-2.71-2.48-4.833-5.374-4.833-2.894 0-5.254 2.123-5.373 4.833L6.421 20H4l.027-.71c.098-2.56 1.658-4.896 4.04-6.135-1.169-.99-1.848-2.402-1.848-3.9C6.219 6.357 8.733 4 11.823 4c3.09 0 5.605 2.357 5.605 5.255 0 1.497-.68 2.909-1.85 3.9 2.383 1.239 3.944 3.574 4.041 6.135zM11.822 6.273c-1.754 0-3.18 1.338-3.18 2.982 0 1.645 1.426 2.982 3.18 2.982 1.754 0 3.18-1.337 3.18-2.982 0-1.644-1.426-2.982-3.18-2.982z'
  />
);

export const person = account;

export const magGlass = (
  <path
    fillRule='evenodd'
    d='M16.083 14.688l3.833 3.764-1.481 1.455-3.878-3.807a6.746 6.746 0 0 1-3.808 1.167C7.028 17.267 4 14.29 4 10.633 4 6.976 7.028 4 10.75 4c3.72 0 6.748 2.976 6.748 6.633 0 1.467-.5 2.894-1.415 4.055zm-.673-4.055c0-2.52-2.09-4.569-4.66-4.569-2.57 0-4.66 2.05-4.66 4.57 0 2.519 2.09 4.569 4.66 4.569 2.57 0 4.66-2.05 4.66-4.57z'
  />
);

export const cart1 = (
  <path
    fillRule='evenodd'
    d='M16.235 17.034c.634 0 1.16.527 1.16 1.164 0 .636-.526 1.164-1.16 1.164-.633 0-1.16-.528-1.16-1.164a1.17 1.17 0 0 1 1.16-1.164zm-8.118 0c.634 0 1.16.527 1.16 1.164 0 .636-.526 1.164-1.16 1.164-.634 0-1.16-.528-1.16-1.164a1.17 1.17 0 0 1 1.16-1.164zm.632-4.492l6.818-.964 1.019-3.5-8.709.081.872 4.383zm.263 2.05l-.024.47h8.727l.028 1.972H7.537a.915.915 0 0 1-.913-.916c0-.218.113-.513.404-1.051l.12-.263L5.63 6.83H4V5h3.178l.289 1.164h11.668l-1.987 7.217-8.136 1.21z'
  />
);

export const chevronUp = (
  <path
    fillRule='evenodd'
    d='M12.231 7l-.484.501h-.001L4 15.51 5.394 17l6.794-7.024 6.375 7.001L20 15.533z'
  />
);

export const chevronDown = (
  <path
    fillRule='evenodd'
    d='M18.605 7l-6.793 7.024-6.375-7.002L4 8.467 11.768 17l.485-.501L20 8.489z'
  />
);

export const chevronLeft = (
  <path
    fillRule='evenodd'
    d='M15.485 4L7 11.769l.499.484-.002.001L15.463 20l1.48-1.394-6.984-6.794 6.963-6.375z'
  />
);

export const chevronRight = (
  <path
    fillRule='evenodd'
    d='M8.49 4L7 5.395l7.024 6.793-7.001 6.375L8.467 20 17 12.232l-.501-.485z'
  />
);

export const arrowRight = (
  <path d='M4 13.6L16.058 13.6L12.86 16.817L14.278 18.228L19.874 12.598L14.277 7L12.863 8.414L16.048 11.6L4 11.6L4 13.6Z' />
);

export const download = (
  <path
    fillRule='evenodd'
    d='M10.87 4h2v8.048l3.187-3.185 1.414 1.414-5.598 5.597-5.63-5.596 1.41-1.418 3.218 3.198V4zm6.991 11.992h2v4H4v-4h2v2h11.861v-2z'
  />
);

export const ok = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm0 1.958c-3.316 0-6.014 2.71-6.014 6.042S8.684 18.042 12 18.042c3.315 0 6.013-2.71 6.013-6.042S15.315 5.958 12 5.958zm3.613 4.32l.175.19-.196.168-4.844 4.205-.182.157-.164-.174-2.315-2.437-.177-.188.193-.17 1.123-.992.185-.163.166.182 1.097 1.166 3.584-3.066.183-.155.162.177 1.01 1.1z'
  />
);

export const freeship = (
  <path
    fillRule='evenodd'
    d='M20.17 10.481v7.439h-2.481a2.2 2.2 0 0 1-2.124 1.643 2.2 2.2 0 0 1-2.124-1.643H9.684a2.2 2.2 0 0 1-2.124 1.643 2.198 2.198 0 0 1-2.123-1.643H3V4h10.72v1.822h3.665l2.785 4.659zm-2.946 5.439h.946v-4.597h-2.784v-2h1.762l-.898-1.501h-2.53v8.098h.186a2.19 2.19 0 0 1 1.659-.767c.666 0 1.255.301 1.659.767zm-5.504 0V6H5v9.92h.901a2.19 2.19 0 0 1 1.659-.767 2.19 2.19 0 0 1 1.66.767h2.5z'
  />
);

export const globe = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm4.343 12.168a6.043 6.043 0 0 0-3.029-10.06l.057.06v1.7h-2.779v2.11h-1.19l1.438 1.929 3.342.553v2.658h1.911l.25 1.05zM5.988 12a6.036 6.036 0 0 0 5.146 5.971c.462-.784 1-2.007-.139-2.007h-.03c-1.681 0-1.281-2.105-1.281-2.105L6.018 11.4c-.02.198-.03.397-.03.6z'
  />
);

export const video = (
  <path
    fillRule='evenodd'
    d='M3 20V4h18v16H3zM16.978 7.454H19V6h-2.022v1.454zm-7.9 3.605h5.9V6h-5.9v5.059zM5.001 7.454h2.077V6H5.001v1.454zm11.977 3.605H19V9.454h-2.022v1.605zm-11.977 0h2.077V9.454H5.001v1.605zm11.977 3.604H19v-1.604h-2.022v1.604zM9.078 18h5.9v-4.941h-5.9V18zm-4.077-3.337h2.077v-1.604H5.001v1.604zm11.977 3.338H19v-1.337h-2.022v1.337zm-11.977 0h2.077v-1.337H5.001v1.337z'
  />
);

export const hamburger = (
  <g>
    <path fillRule='evenodd' d='M4 8h16V6H4z' />
    <path fillRule='evenodd' d='M4 13.096h16v-2.001H4z' />
    <path fillRule='evenodd' d='M4 18.346h16v-2H4z' />
  </g>
);

export const buttonLeft = (
  <path
    fillRule='evenodd'
    d='M12 4a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8zm0 1.958c-3.315 0-6.014 2.71-6.014 6.042S8.685 18.042 12 18.042c3.315 0 6.014-2.71 6.014-6.042S15.315 5.958 12 5.958zm.69 10.1l-3.582-4 3.695-4.117 1.49 1.336-2.5 2.782 2.387 2.665-1.49 1.334z'
  />
);

export const buttonRight = (
  <path
    fillRule='evenodd'
    d='M12 20a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8zm0-1.958c3.315 0 6.014-2.71 6.014-6.042S15.315 5.958 12 5.958c-3.315 0-6.013 2.71-6.013 6.042S8.685 18.042 12 18.042zm-.69-10.1l3.582 4-3.695 4.117-1.49-1.336 2.5-2.782L9.82 9.276l1.49-1.334z'
  />
);

export const close = (
  <path
    fillRule='evenodd'
    d='M17.999 4l-6.293 6.293L5.413 4 4 5.414l6.292 6.293L4 18l1.413 1.414 6.293-6.292 6.293 6.292L19.414 18l-6.294-6.293 6.294-6.293z'
  />
);

export const location = (
  <path
    fillRule='evenodd'
    d='M11.956 4a5.956 5.956 0 0 1 5.956 5.956c0 4.499-5.956 10.044-5.956 10.044S6 14.828 6 9.956A5.957 5.957 0 0 1 11.956 4zm0 2A3.96 3.96 0 0 0 8 9.956c0 2.452 2.244 5.411 3.924 7.215 1.891-2.099 3.988-5.107 3.988-7.215A3.96 3.96 0 0 0 11.956 6zm1.983 3.695a1.983 1.983 0 1 1-3.967 0 1.983 1.983 0 0 1 3.967 0z'
  />
);

export const okCalendar = (
  <path
    fillRule='evenodd'
    d='M13.994 5h1.873v1.015h3.726v13.803H5.005V10.56H5V9.15h.005V6.015h3.728v-.931h1.91v.93h3.35V5zm1.873 3.642h-1.873v-.767h-3.35v.851H8.732v-.85h-1.85v1.273H17.71V7.875h-1.844v.767zm-8.985 9.294h10.83v-6.904H6.882v6.904zm8.207-4.42l.164.178-.184.159-3.391 2.79-.171.147-.155-.164-1.846-1.96-.165-.177.182-.16 1.056-.934.175-.154.155.172.7.765 2.204-1.718.173-.146.152.167.95 1.035z'
  />
);

export const questionBubble = (
  <path
    fillRule='evenodd'
    d='M10.413 18.011l-3.991 1.472.065-3.288C4.882 14.849 4 13.041 4 11.084 4 7.178 7.589 4 12 4s8 3.178 8 7.084c0 3.905-3.589 7.083-8 7.083-.51 0-1.029-.052-1.587-.156zm-2.036-1.278l2.099-.688a6.991 6.991 0 0 0 1.524.182c3.342 0 6.061-2.307 6.061-5.143S15.342 5.94 12 5.94s-6.061 2.308-6.061 5.144c0 1.556.839 3.017 2.303 4.008l.135 1.641zm3.536-9.664c1.473 0 2.501.92 2.501 2.237 0 1.271-.763 1.691-1.268 1.969-.29.161-.427.248-.427.36v.955h-1.517v-.965c0-1.035.614-1.369 1.063-1.613.396-.215.602-.345.602-.726 0-.477-.308-.72-.914-.72-.63 0-.9.286-.9.955v.242H9.496v-.242c0-1.49.95-2.452 2.417-2.452zm-.709 7.674v-1.501h1.502v1.5h-1.502z'
  />
);

export const digitalProduct = (
  <path
    fillRule='evenodd'
    d='M13.566 15.336l1.33 1.39L11.76 20l-3.125-3.23 1.327-1.393.855.873v-4.878H12.7v4.868l.867-.905zm5.114-7.211c.688.872.96 2.029.809 3.44-.272 2.536-2.234 3.123-3.687 3.345l-.242.037-.175-1.803.42-.045c.794-.075 1.882-.177 2.05-1.745.094-.883-.046-1.57-.416-2.042-.643-.82-1.828-.855-1.839-.855l-.58-.009-.19-.603a3.09 3.09 0 0 0-3-2.039c-3.075 0-3.454 3.411-3.47 3.556l-.092.93-.845-.133c-.027-.003-.84-.101-1.33.378-.296.29-.447.779-.447 1.452 0 .419.106.718.334.943.503.497 1.474.554 1.895.554h.023l.238-.001-.005 1.802-.234.001H7.88c-.906 0-2.155-.177-3.002-1.014C4.304 13.707 4 12.917 4 11.989c0-1.217.339-2.161 1.007-2.808.59-.572 1.302-.765 1.85-.819C7.351 6.22 8.898 4 11.83 4c2.66 0 3.883 1.806 4.327 2.707.657.109 1.75.438 2.523 1.418z'
  />
);

export const money = (
  <path
    fillRule='evenodd'
    d='M4 17V7h16v10H4zm9.86-2H18v-2h-1.023v-2H18V9h-4.14c.853.66 1.412 1.756 1.412 3 0 1.245-.559 2.341-1.412 3zM6 13v2h4.139c-.851-.659-1.412-1.755-1.412-3 0-1.244.561-2.34 1.412-3H6v2h.977v2H6zm6 .652c.689 0 1.272-.756 1.272-1.652 0-.896-.583-1.652-1.272-1.652-.69 0-1.273.756-1.273 1.652 0 .896.583 1.652 1.273 1.652z'
  />
);

export const plus = (
  <path
    fillRule='evenodd'
    d='M10.9 4v6.899H4v2h6.9v6.9h2v-6.9h6.898v-2H12.9V4z'
  />
);

export const minus = (
  <path fill='currentColor' fillRule='nonzero' d='M4 13.5h16v-2H4z' />
);

export const clock = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M11.112 8.428v4.584l3.981 2.412.89-1.53-3.095-1.906v-3.56h-1.776zM20 11.988C20 16.414 16.418 20 12.001 20 7.581 20 4 16.414 4 11.988h1.779a6.228 6.228 0 005.26 6.176 6.221 6.221 0 006.892-4.271 6.235 6.235 0 00-3.17-7.47 6.214 6.214 0 00-7.854 2.005h2.427v1.78H4V4.866h1.779v2.11a7.991 7.991 0 018.877-2.521A8.01 8.01 0 0120 11.988z'
    fill='#currentColor'
  />
);

export const alert = (
  <g fill='currentColor' fillRule='evenodd'>
    <path d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z' />
    <circle cx='12' cy='14.875' r='1.125' />
    <rect x='11.1' y='7.89999' width='1.8' height='5.35' />
  </g>
);
